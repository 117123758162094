<template>
	<!-- <ion-content> -->
	<!-- <div class="wrapper"> -->
	<ion-slides :options="sliderOptions" zoom>
		<ion-slide v-for="item in items" :key="item.id">
			<div class="swiper-zoom-container"><img :src="getPath(item.img)" draggable="false" /></div>
		</ion-slide>
	</ion-slides>
	<a class="close-bttn" @click="closeModal"><ion-icon name="add-outline"></ion-icon></a>
	<!-- </div> -->
	<!-- </ion-content> -->
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { IonContent, IonSlides, IonSlide } from '@ionic/vue';
import { modalController } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
export default {
	components: { IonContent, IonSlides, IonSlide },
	props: ['items', 'sliderOptions'],
	methods: {
		closeModal() {
			modalController.dismiss();
		},
		getPath(img) {
			if (img.indexOf('blob') === -1) return this.$store.state.imageBase + '/medium/' + img;
			else return img;
		},
	},
	created() {
		addIcons({
			'add-outline': closeOutline,
		});
	},
};
</script>

<style scoped>
.modal-wrapper {
	--background: red;
}
.wrapper {
	position: relative;
	height: 100%;
	width: 100%;
}

ion-slides {
	height: 100%;
	width: 100%;
}

ion-slide {
	height: 100%;
	width: 100%;
	padding: 15px;
}

img {
	pointer-events: none;
}

.close-bttn {
	display: flex;
	background-color: rgba(255, 255, 255, 0.78);
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	cursor: pointer;
	font-size: 30px;
	padding: 10px;
	color: var(--ion-text-color);
}
</style>
