<template>
	<div v-if="!isLoaded" class="placeholder" ref="placeholder"><img src="/assets/spinner.svg" /></div>
	<img v-show="isLoaded" :src="url" v-bind="$attrs" @load="loaded" />
</template>

<script>
export default {
	inheritAttrs: true,
	props: ['src'],
	data() {
		return {
			url: null,
			isLoaded: false,
		};
	},
	methods: {
		loaded(event) {
			this.isLoaded = true;
			console.log('loaded... ' + event.target.src);
			// console.log(event);
			// this.$refs.placeholder.remove();
		},
	},
	watch: {
		src: {
			immediate: true,
			handler: function(value) {
				// console.log('%cPath: ' + '%c' + value, 'color: green;', 'color:orange;font-style:normal !important;font-size:11px');
				if (!value) return;
				this.url = value;
			},
		},
	},
};
</script>

<style scoped>
.placeholder {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
}
.placeholder img {
	max-width: 35px !important;
	max-height: 35px !important;
}
</style>
