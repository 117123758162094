<template>
	<ion-button v-if="loggedInUser" :router-link="`/edit-user/${loggedInUser.id}`">
		<ion-icon slot="start" name="pencil-outline"></ion-icon>
		Aanpassen
	</ion-button>
	<ion-button @click="logOff">
		<ion-icon slot="start" name="log-out-outline"></ion-icon>
		Afmelden
	</ion-button>
</template>

<script>
import { IonButton } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { pencilOutline, logOutOutline } from 'ionicons/icons';
export default {
	components: { IonButton },
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
	methods: {
		logOff() {
			this.$store.state.chosenUsername = '';
			this.$store.state.chosenPassword = '';
			this.$store.dispatch('logOut');
		},
	},
	created() {
		addIcons({
			'pencil-outline': pencilOutline,
			'log-out-outline': logOutOutline,
		});
	},
};
</script>
