<template>
	<base-layout page-title="Teknon" title-button-type="menu-toggle" padding="ion-no-padding">
		<google-map :curiosities="curiosities" key="home" ref="gMap" :show-current-position-marker="showCurrentPositionMarker"></google-map>
		<template v-slot:outside v-if="loggedInUser">
			<floating-button :rotation="buttonRotation" @click="toggleInstructions"></floating-button>
		</template>
		<add-curiosity-instructions class="addInstructionsWindow" :class="{ show: boolShowInstructions }" @close="boolShowInstructions = false"></add-curiosity-instructions>
	</base-layout>
</template>

<script>
import AddCuriosityInstructions from '@/components/AddCuriosityInstructions';
import FloatingButton from '@/components/FloatingButton';
import GoogleMap from '@/components/GoogleMap';

export default {
	components: {
		AddCuriosityInstructions,
		FloatingButton,
		GoogleMap,
	},
	data() {
		return {
			boolShowInstructions: false,
		};
	},
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
		curiosities() {
			return this.$store.getters.curiosities;
		},
		buttonRotation() {
			return this.boolShowInstructions ? '45' : '0';
		},
		showCurrentPositionMarker() {
			return this.boolShowInstructions;
		},
	},
	methods: {
		toggleInstructions() {
			// if (this.boolShowInstructions) this.$store.dispatch('setNewMarkerPosition', 'MAP_CENTER');
			// else this.$store.dispatch('setNewMarkerPosition', 'NULL');

			this.boolShowInstructions = !this.boolShowInstructions;

			// if (this.boolShowInstructions) this.$store.dispatch('getCurrentPosition');

			// this.$store.dispatch('setNewMarkerPosition', { lat: 0, lng: 0 });
			// const center = window.vm.mapVue.map.getCenter();
			// console.log(window.vm.mapVue.map.getCenter());
			// this.$store.dispatch('setNewMarkerPosition', { lat: center.lat(), lng: center.lng() });
			// this.$store.dispatch('setNewMarkerPosition', window.vm.mapVue.map.getCenter());
		},
	},
	ionViewDidEnter() {
		// console.log('Home page did enter');
		this.$store.dispatch('setVueReady', true);
	},
	ionViewDidLeave() {
		// console.log('Home page did leave');
	},
	ionViewWillEnter() {
		// console.log('Home page will enter');
		// this.thekey++;
	},
	ionViewWillLeave() {
		// console.log('Home page will leave');
		this.$store.dispatch('setVueReady', false);
	},
};
</script>

<style scoped>
.addInstructionsWindow {
	transition: all 300ms;
	transform: translateY(100%);
}
.addInstructionsWindow.show {
	transform: none;
}
</style>
