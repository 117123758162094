<template>
	<ion-menu menu-id="main" content-id="main">
		<!-- <ion-header>
            <ion-toolbar>
                <ion-title>Menu</ion-title>
            </ion-toolbar>
        </ion-header> -->
		<ion-content>
			<div class="menu-profile">
				<div class="menu-profile-avatar">
					<ion-menu-toggle auto-hide="false">
						<router-link to="/account">
							<ion-avatar>
								<img src="/assets/avatar.svg" />
							</ion-avatar>
						</router-link>
					</ion-menu-toggle>
				</div>
				<div class="menu-profile-text" v-if="loggedInUser">
					<div class="menu-profile-name">{{ loggedInUser.user_nicename }}</div>
					<div class="menu-profile-email">
						<a :href="'mailto:' + loggedInUser.user_email">{{ loggedInUser.user_email }}</a>
					</div>
				</div>
				<div v-else>
					<ion-menu-toggle auto-hide="false">
						<!-- <ion-button router-link="/account" fill="outline" size="small">Aanmelden</ion-button> -->
						<router-link to="/account" class="login">Aanmelden</router-link>
					</ion-menu-toggle>
				</div>
				<!-- <ion-item class="menu-profile-edit" button lines="none">
					<ion-icon name="pencil-outline"></ion-icon>
				</ion-item> -->
			</div>
			<ion-list>
				<div v-for="menuItem in menuItems" :key="menuItem">
					<ion-list-header v-if="menuItem.type == 'head'">
						<ion-label>{{ menuItem.label }}</ion-label>
					</ion-list-header>
					<ion-menu-toggle auto-hide="false" v-if="menuItem.type == 'item'">
						<!-- <ion-item :router-link="menuItem.link" button lines="none" :class="activeRoute == menuItem.link ? 'active-item' : ''"> -->
						<ion-item :router-link="menuItem.link" button lines="none" :class="activeRoute.includes(menuItem.link) ? 'active-item' : ''">
							<ion-icon :name="menuItem.icon" slot="start"></ion-icon>
							<ion-label>{{ menuItem.label }}</ion-label>
						</ion-item>
					</ion-menu-toggle>
				</div>
			</ion-list>
			<!-- VUEX TEST -->
			<!-- <div v-for="user in users" :key="user">{{ user }}</div> -->
			<!-- END VUEX TEST -->
		</ion-content>
		<ion-label class="bottom">Teknon</ion-label>
		<!--
        <ion-button router-link="/details">Detail</ion-button>
        <router-link to="/details">Detail</router-link>
        <router-link to="/details"><ion-button>Detail</ion-button></router-link>
        -->
	</ion-menu>
</template>

<script>
import {
	IonMenu,
	IonMenuToggle,
	// IonHeader,
	IonContent,
	// IonToolbar,
	// IonTitle,
	// IonButton,
	// IonButtons,
	IonList,
	IonListHeader,
	IonItem,
	// IonItemDivider,
	// IonItemGroup,
	IonIcon,
	IonLabel,
	IonAvatar,
	// IonThumbnail,
} from '@ionic/vue';
import { addIcons } from 'ionicons';
import { rocket, locationOutline, peopleOutline, personAddOutline, pencilOutline, listOutline, informationCircleOutline, personCircleOutline } from 'ionicons/icons';
// import {  } from '@ionic/vue-router';

export default {
	name: 'App',
	components: {
		IonMenu,
		IonMenuToggle,
		// IonHeader,
		IonContent,
		// IonToolbar,
		// IonTitle,
		// IonButton,
		// IonButtons,
		IonList,
		IonListHeader,
		IonItem,
		// IonItemDivider,
		// IonItemGroup,
		IonIcon,
		IonLabel,
		IonAvatar,
		// IonThumbnail
	},
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
	watch: {
		$route(to) {
			this.activeRoute = to.path;
		},
	},
	data() {
		return {
			menuItems: [
				// { type: 'head', label: 'Algemeen' },
				{ type: 'item', label: 'Kaart', icon: 'location-outline', link: '/home' },
				// { type: 'head', label: 'Gebruikers' },
				{ type: 'item', label: 'Lijst', icon: 'list-outline', link: '/list' },
				{ type: 'item', label: 'Eigenaars', icon: 'people-outline', link: '/users' },
				{ type: 'item', label: 'Account', icon: 'person-circle-outline', link: '/account' },
				{ type: 'item', label: 'App-Informatie', icon: 'information-circle-outline', link: '/about' },
				// { type: 'item', label: 'Toevoegen', icon: 'person-add-outline', link: '/adduser' },
			],
			activeRoute: this.$route.path,
		};
	},
	mounted() {
		addIcons({
			rocket: rocket,
			'location-outline': locationOutline,
			'people-outline': peopleOutline,
			'person-add-outline': personAddOutline,
			'pencil-outline': pencilOutline,
			'list-outline': listOutline,
			'information-circle-outline': informationCircleOutline,
			'person-circle-outline': personCircleOutline,
		});
	},
};
</script>

<style scoped>
ion-content,
ion-list,
ion-item {
	--background: var(--ion-color-light-tint);
	background: var(--ion-color-light-tint);
}

.menu-profile {
	/* background: var(--ion-color-dark); */
	/* color: var(--ion-color-dark-contrast); */
	/* background: var(--ion-color-light-tint); */
	/* color: var(--ion-text-color); */
	padding: 30px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	margin-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.075);
	margin: 0 16px 10px 16px;
}

.menu-profile-avatar {
	margin-bottom: 10px;
}

.menu-profile-text {
	/* margin-left: 20px; */
	text-align: center;
}

.menu-profile-name {
	font-size: 1.2em;
	/* font-weight: 600; */
	margin-bottom: 2px;
}
.menu-profile-email {
	font-size: 0.8em;
	font-weight: 300;
}
.menu-profile-email a {
	/* color: var(--ion-color-primary-contrast); */
	color: var(--ion-text-color);
	text-decoration: none;
}

.menu-profile-edit {
	--min-height: 0;
	--min-width: 0;
	--background: none;
	--color: #fff !important;
	position: absolute;
	right: 0;
	top: 0;
}
.login {
	/* color: var(--ion-color-light-contrast); */
	color: var(--ion-text-color);
	text-decoration: none;
}
.bottom {
	display: none;
	width: 100%;
	padding: 10px;
	text-align: center;
	font-size: 0.85em;
	font-weight: 500;
}
.active-item {
	--color: var(--ion-color-primary);
}
.active-item ion-icon {
	color: var(--ion-color-primary);
}
ion-item {
	--background-activated: red;
	--background-focused: green;
	/* --background-hover: var(--ion-color-primary); */
	--background-hover: #a8aaad;
	--ripple-color: #a8aaad;
	--background-hover-opacity: 0.25;
}
</style>
