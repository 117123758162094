<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-menu-toggle auto-hide="true" v-if="titleButtonType === 'menu-toggle'">
						<ion-button>
							<ion-icon slot="icon-only" name="menu-outline"></ion-icon>
						</ion-button>
					</ion-menu-toggle>
					<ion-back-button :default-href="defaultBack" v-else></ion-back-button>
				</ion-buttons>
				<ion-title>{{ pageTitle }}</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :class="padding">
			<slot></slot>
		</ion-content>

		<slot name="outside" style="background:red;"></slot>
	</ion-page>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonButtons, IonBackButton, IonMenuToggle, IonIcon } from '@ionic/vue';

export default {
	name: 'Home',
	props: {
		pageTitle: {
			default: '',
		},
		titleButtonType: {
			default: '',
		},
		defaultBack: {
			default: '/home',
		},
		padding: {
			default: 'ion-padding',
		},
	},
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonButton,
		IonButtons,
		IonBackButton,
		IonMenuToggle,
		IonIcon,
	},
};
</script>

<style scoped></style>
