<template>
	<div :ref="'map-' + mapId" class="map"></div>
</template>

<script>
import { IonButton } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { addOutline } from 'ionicons/icons';

export default {
	// eslint-disable-next-line vue/no-unused-components
	components: { IonButton },
	data() {
		return {
			mainTimer: null,
			mapInitialized: false,
			map: null,
			markers: [],
			currentPositionMarker: null,
		};
	},
	props: {
		curiosities: {
			default: [],
		},
		mapMode: {
			default: 'multiple',
		},
		showCurrentPositionMarker: {
			default: false,
		},
	},
	computed: {
		currentPosition() {
			return this.$store.getters.currentPosition;
		},
		addNewMarkerPosition() {
			return this.$store.getters.addNewMarkerPosition;
		},
		googleMapOptions() {
			return this.$store.getters.googleMapOptions;
		},
		googleMapScriptLoaded() {
			return this.$store.getters.googleMapScriptLoaded;
		},
		vueReady() {
			return this.$store.getters.vueReady;
		},
		myPosition() {
			return this.currentPosition ? `${this.currentPosition.lat} / ${this.currentPosition.lng}` : 'loading...';
		},
		curiosityIds() {
			return this.curiosities.map((curiosity) => curiosity.id);
		},
		mapId() {
			// return Date.now();
			return this.mapMode + '-test-123';
		},
	},
	watch: {
		curiosities: {
			deep: true, // This will let Vue know to look inside the array
			handler: function() {
				// if (this.mapMode === 'details') return;
				this.clearDeletedMarkers();
				this.drawMarkers();
				// console.log('> Curiosities Changed');
				// console.log(value);
			},
		},
		currentPosition(value) {
			this.updateCurrentPositionMarker(value);
		},
		addNewMarkerPosition(value) {
			this.updateCurrentPositionMarker(value);
		},
		googleMapScriptLoaded(value) {
			if (value) this.initMap();
			console.log('> Map Script Status Changed: ' + value);
		},
		vueReady(value) {
			if (value) this.initMap();
			else this.clearMap();
			// console.log('> Vue Ready Status Changed: ' + value);
		},
		showCurrentPositionMarker(value) {
			if (!this.currentPositionMarker) return;
			this.currentPositionMarker.setVisible(value);
			if (!value && typeof this.currentPositionMarker.infowindow !== 'undefined') this.currentPositionMarker.infowindow.close();
		},
	},
	mounted() {
		// console.log('Map component did mount');
		this.initMap();
	},
	unmounted() {
		this.clearMap();
	},
	methods: {
		elTest() {
			// // console.log('>> TickTest Executed');
			// this.$nextTick(() => {
			// const el = document.querySelector('.gm-control-active');
			const el = this.$refs['map-' + this.mapId].querySelector('.gm-control-active');
			if (!el) {
				// this.tickTest();
				// console.log('>> Element Not Found!');
				cancelAnimationFrame(this.mainTimer);
				this.mainTimer = requestAnimationFrame(() => this.elTest());
			} else {
				// console.log(el);
				// console.log('>> Element Exists!');
				this.drawMarkers();
				// clearInterval(this.mainTimer);
			}
			// });
		},
		forceUpdate() {
			// console.log('> Force Update');
			this.clearMap();
			this.initMap();
		},
		clearMap() {
			// console.log('> Clear Map');
			if (this.map) this.map.getDiv().innerHTML = '';
			this.markers = [];
			this.mapInitialized = false;
			this.map = null;
		},
		visitDetailsPage($id) {
			this.$router.push('/list/' + $id);
		},
		checkInnerHtml() {
			// let innerHtml = document.getElementById('map-' + this.mapId).innerHTML;
			if (!this.map) return false;
			let innerHtml = this.map.getDiv().innerHTML;
			// console.log(innerHtml);
			return innerHtml === '' ? false : true;
			// return document.querySelector('.gm-control-active') ? true : false;
		},
		updateCurrentPositionMarker(coords) {
			if (!this.currentPositionMarker) return;

			this.currentPositionMarker.setPosition({
				lat: coords.lat,
				lng: coords.lng,
			});
			// this.currentPositionMarker.setVisible(true);
			this.currentPositionMarker.setZIndex(9999);
		},
		getCurrentPosition() {
			this.$store.dispatch('getCurrentPosition');
		},
		initMap() {
			// console.log('Init Google Map');
			// Store this Vue instance in an accessable window property so we can access its functions from the GoogleMaps infoWindow
			window.vm.mapVue = this;

			// This map is already initialized
			if (this.mapInitialized) return;

			// Check if the main Google Maps script is loaded
			if (!this.googleMapScriptLoaded) return;

			// Check if vue is ready
			if (!this.vueReady) return;

			// Clear Current Position Marker Variable
			this.currentPositionMarker = null;

			this.mapInitialized = true;

			// Update Map Options
			this.googleMapOptions.gestureHandling = this.mapMode === 'details' ? 'cooperative' : 'auto';
			this.googleMapOptions.zoomControlOptions = {
				position: this.mapMode === 'details' ? window.google.maps.ControlPosition.LEFT_CENTER : window.google.maps.ControlPosition.RIGHT_CENTER,
			};
			this.$store.commit('setGoogleMapOptions', this.googleMapOptions);

			this.map = new window.google.maps.Map(this.$refs['map-' + this.mapId], this.googleMapOptions);

			// window.google.maps.event.trigger(this.map, 'resize');

			// console.log('Loaded map.');

			// Click listener to close all infowindows upon map click
			window.google.maps.event.addListener(this.map, 'click', () => this.closeAllInfoWindows());

			// Test
			// window.google.maps.event.addListenerOnce(this.map, 'idle', () => {
			// 	// console.log('> Trigger Event Idle');
			// 	// console.log(document.querySelector('.gm-control-active'));
			// });
			window.google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
				// console.log('> Tiles Loaded!!!');
				// this.elTest();
				this.drawMarkers();
			});

			// Draw All Markers
			// this.clearDeletedMarkers();
			// this.drawMarkers();

			// Set map center to details
			if (this.mapMode === 'details' && this.curiosities.length > 0) {
				const firstCuriosity = this.curiosities[0];
				// console.log('curiosities:');
				// console.log(this.curiosities);
				// console.log(firstCuriosity);
				this.map.setCenter({ lat: parseFloat(firstCuriosity.lat), lng: parseFloat(firstCuriosity.lng) });
			}
		},
		closeAllInfoWindows() {
			for (var i = 0; i < this.markers.length; i++) {
				if (typeof this.markers[i].infowindow !== 'undefined') this.markers[i].infowindow.close();
			}
			if (typeof this.currentPositionMarker.infowindow !== 'undefined') this.currentPositionMarker.infowindow.close();
		},
		drawMarkers() {
			// console.log('> Begin drawMarkers');
			if (typeof window.google === 'undefined') return;
			// console.log('> window.google is defined');

			// This map is not yet initialized
			if (!this.mapInitialized) return;

			// Check if the main Google Maps script is loaded
			if (!this.googleMapScriptLoaded) return;

			// Check if vue is ready
			if (!this.vueReady) return;

			// console.log('> Continue drawMarkers');
			// Current Position Marker
			if (!this.currentPositionMarker && this.mapMode !== 'details') {
				this.currentPositionMarker = new window.google.maps.Marker({
					// position: { lat: parseFloat(lat), lng: parseFloat(lng) },
					position: this.map.getCenter(),
					map: this.map,
					icon: '/assets/markers/map-marker-green.svg',
					draggable: true,
					animation: window.google.maps.Animation.DROP,
					visible: this.showCurrentPositionMarker,
					zIndex: 9999,
				});
				// this.currentPositionMarker.addListener('click', () => this.$store.dispatch('setCurrentPosition', this.currentPositionMarker.getPosition()));
				// this.currentPositionMarker.addListener('click', () => {});
				this.currentPositionMarker.addListener('drag', () => {
					const pos = this.currentPositionMarker.getPosition();
					const coords = { lat: pos.lat(), lng: pos.lng() };
					this.$store.dispatch('setNewMarkerPosition', coords);
				});
				const pos = this.currentPositionMarker.getPosition();
				const coords = { lat: pos.lat(), lng: pos.lng() };
				this.$store.dispatch('setNewMarkerPosition', coords);

				this.currentPositionMarker.infowindow = new window.google.maps.InfoWindow({
					content: `
							<div class="info-window-add-new">
								<div class="ion-text-center">
									<h4>Toevoegen</h4>
									<p>U kunt op deze plaats een oldtimer toevoegen.</p>
									<ion-button expand="block" onclick="window.vm.mapVue.visitDetailsPage('add')">
										<ion-icon slot="start" name="add-outline"></ion-icon>
										Hier Toevoegen
									</ion-button>
								</div>
							</div>
							`,
				});

				this.currentPositionMarker.addListener('click', () => {
					this.closeAllInfoWindows(); // Close all other infowindows first
					this.currentPositionMarker.infowindow.open(this.map, this.currentPositionMarker); // Open this infowindow
				});

				/*
				this.currentPositionMarker.addListener('click', () => {
					if (this.currentPositionMarker.getAnimation() !== null) {
						this.currentPositionMarker.setAnimation(null);
					} else {
						this.currentPositionMarker.setAnimation(window.google.maps.Animation.BOUNCE);
						setTimeout(() => {
							if (this.currentPositionMarker) this.currentPositionMarker.setAnimation(null);
							// console.log('stop bouncing');
						}, 1500);
					}
				});
				*/
			}

			// Create Marker for each curiosity (oldtimer)
			// console.log('> Loop curiosities (markers)');
			// console.log(this.curiosities);
			this.curiosities.forEach((curiosity) => {
				// Check if there already is a marker that has a curiosity in it with the matching id
				let marker = this.markers.find((marker) => marker.curiosity.id === curiosity.id);

				if (marker === undefined) {
					// The marker doesn't exist yet so we draw a new one
					const markerColor = parseInt(curiosity.approved) === 1 ? 'blue' : 'red';
					marker = new window.google.maps.Marker({
						position: { lat: parseFloat(curiosity.lat), lng: parseFloat(curiosity.lng) },
						map: this.map,
						// animation: window.google.maps.Animation.DROP,
						animation: null,
						icon: '/assets/markers/map-marker-' + markerColor + '.svg',
					});

					// Put a new infowindow inside the new marker and open it when the marker is clicked (not standard practise)
					if (this.mapMode !== 'details') {
						marker.infowindow = new window.google.maps.InfoWindow();
						marker.addListener('click', () => {
							this.closeAllInfoWindows(); // Close all other infowindows first
							marker.infowindow.open(this.map, marker); // Open this infowindow
						});
					} else {
						marker.addListener('click', () => {
							if (marker.getAnimation() !== null) {
								marker.setAnimation(null);
							} else {
								marker.setAnimation(window.google.maps.Animation.BOUNCE);
								setTimeout(() => {
									marker.setAnimation(null);
									// console.log('stop bouncing');
								}, 1500);
							}
						});
					}

					// Add the new marker to the array
					this.markers.push(marker);
				} else {
					// console.log('> Marker Already in Array:');
					// console.log(marker);
					// console.log(this.markers);
					marker.setVisible(true);
				}

				// Set Excerpt
				const maxlength = 100;
				curiosity.excerpt = curiosity.description.length > maxlength ? curiosity.description.substr(0, maxlength - 1) + '&hellip;' : curiosity.description;

				// Add / Update curiosity info and infowindow inside the marker so we can access it and it updates in realtime even when opened
				marker.curiosity = curiosity;

				// Date formatted
				// // console.log(this);
				const date_formatted = this.formatDate(marker.curiosity.date_added);

				// Info Window Content
				if (this.mapMode !== 'details') {
					const contentString = `
						<div class="info-window">
							<div>
							<div>
								<h3 class="main-title">${marker.curiosity.title}
								</h3>
								<div class="date">${date_formatted}</div>
								<p>${marker.curiosity.excerpt}</p>
								<ion-button fill="outline" onclick="window.vm.mapVue.visitDetailsPage(${marker.curiosity.id})">
									<ion-icon slot="start" src="/assets/map-launchers/info-circle-solid.svg"></ion-icon>
									Lees Meer
								</ion-button>
							</div>
							<div class="ion-text-center">
								<!--<h6>Navigeer<ion-icon slot="end" class="external-link" src="/assets/map-launchers/external-link-alt-solid.svg"></ion-icon></h6>-->
								<ion-button onclick="window.vm.mapVue.launchNavigationApp('google-maps', ${marker.curiosity.lat}, ${marker.curiosity.lng})">
									<ion-icon slot="start" src="/assets/map-launchers/map-marked-alt-solid.svg"></ion-icon>
									Google Maps
								</ion-button>
								<ion-button onclick="window.vm.mapVue.launchNavigationApp('waze', ${marker.curiosity.lat}, ${marker.curiosity.lng})">
									<ion-icon slot="start" src="/assets/map-launchers/waze-brands-filled.svg"></ion-icon>
									Waze
								</ion-button>
								<!--
								<ion-button class="navigate" onclick="window.vm.mapVue.launchNavigationApp('google-maps', ${marker.curiosity.lat}, ${marker.curiosity.lng})">
									<ion-icon slot="icon-only" src="/assets/map-launchers/map-marked-alt-solid.svg"></ion-icon>
								</ion-button>
								<ion-button class="navigate" onclick="window.vm.mapVue.launchNavigationApp('waze', ${marker.curiosity.lat}, ${marker.curiosity.lng})">
									<ion-icon slot="icon-only" src="/assets/map-launchers/waze-brands-filled.svg"></ion-icon>
								</ion-button>
								-->
							</div>
							</div>
						</div>
					`;

					if (marker.infowindow.getContent() !== contentString) marker.infowindow.setContent(contentString);
				}
			});
		},
		clearDeletedMarkers() {
			for (let i = this.markers.length - 1; i > 0; i--) {
				if (!this.curiosityIds.includes(this.markers[i].curiosity.id)) {
					this.markers[i].setMap(null);
					this.markers[i] = null;
					this.markers.splice(i, 1);
				}
			}
			// console.log(this.markers);
		},
	},
	created() {
		addIcons({
			'add-outline': addOutline,
		});
	},
};
</script>

<style>
.map {
	width: 100%;
	height: 100%;
}
.map * {
	overflow: visible;
	/* max-width: none !important; */
}

.map img {
	max-width: none !important;
}
/* InfoWindow Main Title */
.gm-style .gm-style-iw .main-title {
	margin-bottom: 0;
}

/* InfoWindow Text */
.gm-style .gm-style-iw {
	font-weight: 400;
	font-size: 1.3em;
	line-height: 1.2em;
}

/* InfoWindow Text */
.gm-style .gm-style-iw .date {
	font-weight: 500;
}

/* InfoWindow Close Button */
.gm-style-iw.gm-style-iw-c > button {
	top: 0px !important;
	right: 0px !important;
}
.external-link {
	font-size: 0.8em;
	margin-left: 5px;
}

.navigate {
	--border-radius: 50%;
	height: 60px;
	width: 60px;
}
/* InfoWindow Add New */
.info-window-add-new .gm-style-iw.gm-style-iw-c > button {
	display: none;
}

.addmystyle {
	background: blue !important;
}
#add-window-container {
	position: absolute;
	left: 50%;
	top: 50%;
}
#add-window-container > div {
	background: red;
	padding: 10px;
	transform: translateX(-50%);
}
</style>
