<template>
	<base-layout page-title="Eigenaars" title-button-type="menu-toggle">
		<div class="filter" v-if="loggedInUser.user_type === 'god' || loggedInUser.user_type === 'admin'">
			<ion-item lines="none" v-if="loggedInUser.user_type === 'god'">
				<!-- <ion-toggle checked value="god" @ionChange="filterUsers" ref="showGod"></ion-toggle> -->
				<ion-toggle @ionChange="filterUsers" v-model="showGod" ref="showGod"></ion-toggle>
				<ion-label>God</ion-label>
			</ion-item>
			<ion-item lines="none">
				<ion-toggle @ionChange="filterUsers" v-model="showAdmin" ref="showAdmin"></ion-toggle>
				<ion-label>Admin</ion-label>
			</ion-item>
			<ion-item lines="none">
				<ion-toggle @ionChange="filterUsers" v-model="showClient" ref="showClient"></ion-toggle>
				<ion-label>Klanten</ion-label>
			</ion-item>
			<ion-item lines="none">
				<ion-toggle @ionChange="filterUsers" v-model="showInactive" ref="showInactive"></ion-toggle>
				<ion-label>Inactief</ion-label>
			</ion-item>
		</div>
		<ion-list lines="full">
			<ion-item v-for="user in filteredUsers" :key="user.id" :router-link="`/users/${user.id}`" button>
				<ion-avatar slot="start">
					<img src="/assets/avatar.svg" />
				</ion-avatar>
				<ion-label>
					<h2>{{ user.user_nicename }}</h2>
					<h4 v-if="user.user_login && loggedInUser && (loggedInUser.user_type === 'god' || loggedInUser.user_type === 'admin')">{{ user.user_login }} ({{ user.user_type }})</h4>
				</ion-label>
				<div v-if="user.user_active" slot="end">
					<ion-text v-if="user.user_active == 1" color="success">Actief</ion-text>
					<ion-text v-else color="danger">Inactief</ion-text>
				</div>
			</ion-item>
		</ion-list>
		<template v-slot:outside v-if="loggedInUser.user_type === 'god' || loggedInUser.user_type === 'admin'">
			<router-link to="/users/add"><floating-button></floating-button></router-link>
		</template>
	</base-layout>
</template>

<script>
import {
	IonList,
	IonItem,
	// IonChip,
	// IonCard,
	// IonCardTitle,
	// IonCardSubtitle,
	IonToggle,
	IonLabel,
	IonAvatar,
	IonText,
} from '@ionic/vue';
import FloatingButton from '../components/FloatingButton.vue';

export default {
	components: {
		IonList,
		IonItem,
		// IonChip,
		// IonCard,
		// IonCardTitle,
		// IonCardSubtitle,
		IonToggle,
		IonLabel,
		IonAvatar,
		IonText,
		FloatingButton,
	},
	data() {
		return {
			showGod: true,
			showAdmin: true,
			showClient: true,
			showInactive: true,
			filteredUsers: [],
		};
	},
	watch: {
		users() {
			this.filterUsers();
		},
	},
	computed: {
		users() {
			return this.$store.getters.users;
		},
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
	methods: {
		filterUsers() {
			window.vm.userList = this;
			this.filteredUsers = [];
			if (!this.users) return;
			if (!this.loggedInUser.user_type || this.loggedInUser.user_type === 'client') {
				// User is not logged in
				this.filteredUsers = this.users.filter((user) => user.user_type !== 'god');
				return;
			}

			this.users.forEach((user) => {
				// Inactive
				if (user.user_active == 0 && (!this.$refs.showInactive || !this.showInactive)) return;
				// User type
				if (user.user_type === 'god' && this.$refs.showGod && this.showGod) this.filteredUsers.push(user);
				else if (user.user_type === 'admin' && this.$refs.showAdmin && this.showAdmin) this.filteredUsers.push(user);
				else if (user.user_type === 'client' && this.$refs.showClient && this.showClient) this.filteredUsers.push(user);
			});
			// console.log('this.filteredUsers ->', this.filteredUsers);
		},
	},
	mounted() {
		this.filterUsers();
	},
};
</script>

<style scoped>
.filter {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
/* * {
	background: none;
} */
</style>
