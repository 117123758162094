<template>
	<div class="container">
		<div class="content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
	/* background: red; */
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	justify-items: center;
	/* background: red !important; */
	min-height: 100%;
	width: 100%;
	overflow: visible;
}
.content {
	text-align: center;
	padding-bottom: var(--ion-padding, 16px);
}
</style>
