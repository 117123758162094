<template>
	<base-layout page-title="Toevoegen" default-back="/list">
		<curiosity-form mode="add" :key="Date.now()"></curiosity-form>
		<!-- <curiosity-form mode="add" v-if="loggedInUser"></curiosity-form> -->
		<!-- <unauthorized v-else></unauthorized> -->
	</base-layout>
</template>

<script>
import CuriosityForm from '../components/CuriosityForm';
// import Unauthorized from '../components/Unauthorized';

export default {
	components: {
		CuriosityForm,
		// Unauthorized
	},
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
};
</script>

<style></style>
