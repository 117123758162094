<template>
	<transition-group name="slidedown" tag="ion-text">
		<ion-text color="danger" v-for="error in errors" :key="error">
			{{ error }}
		</ion-text>
	</transition-group>
</template>

<script>
import { IonText } from '@ionic/vue';
export default {
	components: { IonText },
	computed: {
		errors() {
			return this.$store.getters.errors;
		},
	},
	mounted() {
		this.$store.commit('clearErrors');
		// this.$store.commit('addError', 'test van in component');
	},
};
</script>

<style scoped>
ion-text {
	display: block;
	margin: 5px auto 5px auto;
	max-width: 300px;
	font-weight: 600;
}

/* Animation: Fade */

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
	transition: max-height 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

/* Animation: Slide Down */

.slidedown-enter-active,
.slidedown-leave-active {
	transition: max-height 750ms ease;
}

.slidedown-enter-to,
.slidedown-leave-from {
	overflow: hidden;
	max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
