<template>
	<form v-if="userData" @submit.prevent="submitForm">
		<ion-list>
			<ion-item>
				<ion-label position="floating">Gebruikersnaam<span class="required"></span></ion-label>
				<ion-input clear-input v-model="userData.user_login" type="text" required></ion-input>
			</ion-item>
			<ion-item>
				<ion-label position="floating">Wachtwoord<span class="required"></span><span v-if="mode === 'edit'"> (laat leeg om huidig wachtwoord te behouden)</span></ion-label>
				<ion-input clear-input v-model="userData.user_pass" type="password" required></ion-input>
			</ion-item>
			<ion-item>
				<ion-label position="floating">Naam<span class="required"></span></ion-label>
				<ion-input clear-input v-model="userData.user_nicename" type="text" required></ion-input>
			</ion-item>
			<ion-item>
				<ion-label position="floating">E-Mail<span class="required"></span></ion-label>
				<ion-input clear-input v-model="userData.user_email" type="email" required></ion-input>
			</ion-item>
			<ion-item v-if="userData.user_notes !== undefined">
				<ion-label position="floating">Notities</ion-label>
				<ion-textarea v-model="userData.user_notes" cols="40" rows="5"></ion-textarea>
			</ion-item>
			<ion-item v-if="userData.user_type !== undefined">
				<ion-label position="floating">Type</ion-label>
				<ion-select placeholder="Selecteer" :value="userData.user_type" @ionChange="userData.user_type = $event.target.value" interface="popover" ok-text="Oké" cancel-text="Annuleer">
					<ion-select-option value="client">Klant / Eigenaar</ion-select-option>
					<ion-select-option value="admin">Administrator</ion-select-option>
					<ion-select-option value="god" v-if="loggedInUser.user_type === 'god'">God</ion-select-option>
				</ion-select>
			</ion-item>
			<ion-item v-if="userData.user_active !== undefined && loggedInUser && (loggedInUser.user_type == 'god' || loggedInUser.user_type == 'admin')">
				<ion-label>Actief</ion-label>
				<ion-toggle :checked="userIsActive" v-model="userIsActive" @ionChange="userData.user_active = $event.target.checked ? 1 : 0"></ion-toggle>
			</ion-item>
		</ion-list>
		<app-errors>{{ error }}</app-errors>
		<ion-button type="submit">{{ mode === 'add' ? 'Toevoegen' : 'Aanpassen' }}</ion-button>
		<ion-button @click="goBack">Annuleer</ion-button>
	</form>
</template>

<script>
import axios from 'axios';
import { IonButton, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle } from '@ionic/vue';
import AppErrors from '../components/AppErrors';

export default {
	components: { IonButton, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, AppErrors },
	data() {
		return {
			test: '0',
			userData: {
				id: '',
				user_login: '',
				user_pass: '',
				user_nicename: '',
				user_email: '',
				user_notes: '',
				user_type: 'client',
				user_active: '1',
			},
		};
	},
	props: {
		user: {
			default: null,
		},
		mode: {
			default: 'add', // add or edit
		},
	},
	computed: {
		loadedUser() {
			return this.$store.getters.user(this.$route.params.id);
		},
		userIsActive() {
			if (this.loadedUser) return this.loadedUser.user_active == 1 ? true : false;
			else return true;
		},
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
	watch: {
		user: {
			immediate: true, // This will execute no initial page load
			deep: true, // This will let Vue know to look inside the array
			handler: function(val) {
				if (!val) return;
				this.userData = val;
			},
		},
	},
	methods: {
		goBack() {
			// this.$router.go(-1); // Go back 1 step
			if (this.mode === 'edit') this.$router.replace('/users/' + this.loadedUser.id);
			else this.$router.replace('/users');
		},
		submitForm() {
			// Form Action (add / edit)
			const formAction = this.mode === 'add' ? 'adduser' : 'edituser';

			// Clear Errors
			this.errors = [];

			// Data
			let formData = new FormData();
			for (let key in this.userData) {
				formData.append(key, this.userData[key]);
			}
			if (this.loadedUser) formData.append('id', this.loadedUser.id);

			// Ajax / Axios
			axios
				.post('', formData, {
					params: {
						action: formAction,
					},
					auth: {
						username: this.$store.state.chosenUsername,
						password: this.$store.state.chosenPassword,
					},
				})
				.then((response) => {
					console.log(response);
					if (response.data.result === 'success') {
						this.$store.dispatch('logIn');
						this.$store.dispatch('fetchUsers').then(() => {
							this.goBack();
						});
					} else {
						if (typeof response.data.message !== undefined) this.errors.push(response.data.message);
					}
				})
				.catch((err) => {
					console.log(err);
					return null;
				});
		},
	},
};
</script>

<style></style>
