<template>
	<div class="wrapper" v-if="items.length > 0">
		<div class="main-container">
			<div v-for="item in items" :key="item.id" class="item-container">
				<div class="image-container" :class="{ disabled: locked }">
					<!-- <img v-if="item.img !== 'invalid'" :src="item.path + item.img" @click="openModal(index)" tappable /> -->
					<image-preload v-if="item.img !== 'invalid'" :src="item.path + item.img" @click="openModal(getSliderItemIndexById(item.id))" class="test" fddf="dmlfk" tappable></image-preload>
					<img v-else class="invalid" src="/assets/times-circle-regular.svg" />
				</div>
				<div class="button-container" v-if="!locked && mode != 'details'">
					<ion-button size="small" class="button" @click="$emit('delete', item.id, item.upload_perc)" fill="clear"><ion-icon slot="icon-only" name="close-outline"></ion-icon></ion-button>
				</div>
				<div class="error" v-if="item.error">
					<div class="message">{{ item.message }}</div>
				</div>
				<div class="progress-bar" :class="{ completed: parseInt(item.upload_perc) >= 100 }" :style="{ width: item.upload_perc + '%' }"></div>
			</div>
		</div>
	</div>
	<div class="empty" :class="{ disabled: locked }" v-else-if="mode != 'details'">Er zijn geen foto's geselecteerd.</div>
</template>

<script>
import { IonButton, IonIcon, modalController } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import ImagePreload from './ImagePreload';
import ImageSlider from './ImageSlider';

export default {
	components: { IonButton, IonIcon, ImagePreload },
	props: {
		items: {
			default: [],
		},
		mode: {
			default: '',
		},
		locked: {},
	},
	computed: {
		sliderItems() {
			return this.items.filter((item) => item.error === false);
		},
	},
	methods: {
		async openModal(index) {
			const modal = await modalController.create({
				component: ImageSlider,
				cssClass: 'teknon-image-slider',
				componentProps: {
					items: this.sliderItems,
					sliderOptions: {
						initialSlide: index,
						speed: 400,
						// autoHeight: true,
						centeredSlides: true,
						centeredSlidesBounds: true,
						grabCursor: true,
						loop: true,
						spaceBetween: 10,
						cssMode: false,
					},
				},
			});
			return modal.present();
		},
		getSliderItemIndexById(id) {
			return this.sliderItems.findIndex((item) => item.id === id);
		},
	},
	created() {
		addIcons({
			'close-outline': closeOutline,
		});
	},
};
</script>

<style>
.teknon-image-slider .modal-wrapper {
	/* --background: red; */
	height: 100%;
	width: 100%;
}
</style>
<style scoped>
.wrapper {
	width: 100%;
}
.main-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 15px;
}
@media (max-width: 560px) {
	.main-container {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media (max-width: 400px) {
	.main-container {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media (max-width: 300px) {
	.main-container {
		grid-template-columns: 1fr;
	}
}
.main-container > .item-container {
	border: 1px solid var(--ion-item-border-color, rgba(0, 0, 0, 0.13));
	position: relative;
}
.main-container > .item-container:before {
	content: '';
	display: block;
	padding-bottom: 100%;
}

.image-container {
	background: var(--ion-color-light);
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}
.image-container > img,
.image-container >>> img {
	object-fit: cover;
	/* display: none; */
	width: 100%;
	height: 100%;

	position: absolute;
	z-index: 1;
}
.image-container > img.invalid,
.image-container >>> img.invalid {
	transform: scale(0.5);
}

.image-container.disabled {
	opacity: 0.35;
}
.empty {
	background: var(--ion-color-light);
	padding: 40px 20px;
	text-align: center;
}
.empty.disabled {
	opacity: 0.35;
}
.main-container > div > div.button-container {
	position: absolute;
	right: 10px;
	top: 10px;
	background: none;
	z-index: 3;
}

.button-container .button {
	background: rgba(0, 0, 0, 0.4);
	color: #fff;
	height: 40px;
	width: 40px;
	margin: 0;
	border-radius: 100%;
	--padding-bottom: 0;
	--padding-end: 0;
	--padding-start: 0;
	--padding-top: 0;
	/* padding: 20px; */
}

.progress-bar {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 3px;
	background-color: var(--ion-color-primary);
	z-index: 2;
}

.progress-bar.completed {
	background-color: var(--ion-color-success);
}

.error {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--ion-color-danger);
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 0;
	text-align: center;
	background: rgba(0, 0, 0, 0.35);
	z-index: 2;
}

.error .message {
	background: var(--ion-color-danger);
	color: var(--ion-color-danger-contrast);
	width: 100%;
	padding: 10px;
	opacity: 0.9;
}
</style>
<style>
.sc-ion-modal-md-h {
	--max-width: calc(100% - 30px);
	--max-height: calc(100% - 30px);
}
</style>
