<template>
	<base-layout :pageTitle="loadedCuriosity ? loadedCuriosity.title : 'Details'" default-back="/list">
		<div v-if="loadedCuriosity">
			<marker-info :curiosity="loadedCuriosity"></marker-info>
			<ion-button v-if="loggedInUser.user_type === 'god' || loggedInUser.user_type === 'admin' || (loadedCuriosity && loggedInUser.id === loadedCuriosity.author)" :router-link="`/edit/${loadedCuriosity.id}`">
				<ion-icon slot="start" name="pencil-outline"></ion-icon>
				Aanpassen
			</ion-button>
			<ion-button v-if="loggedInUser.user_type === 'god' || loggedInUser.user_type === 'admin' || (loadedCuriosity && loggedInUser.id === loadedCuriosity.author)" @click="deleteCuriosity">
				<ion-icon slot="start" name="trash-outline"></ion-icon>
				Verwijderen
			</ion-button>
		</div>
		<center-page v-else-if="pageReady">Deze informatie kon niet gevonden worden.</center-page>
	</base-layout>
</template>

<script>
import axios from 'axios';
import BaseLayout from '@/components/BaseLayout.vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { pencilOutline, trashOutline } from 'ionicons/icons';
import MarkerInfo from '../components/MarkerInfo';
import CenterPage from '../components/CenterPage';

export default {
	components: {
		BaseLayout,
		IonButton,
		IonIcon,
		MarkerInfo,
		CenterPage,
	},
	data() {
		return {
			pageReady: false,
		};
	},
	computed: {
		loadedCuriosity() {
			return this.$store.getters.curiosity(this.markerId);
		},
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
		markerId() {
			return this.$route.params.id;
		},
	},
	methods: {
		deleteCuriosity() {
			// TODO: Confirmation message / popup / modal

			// Check if loaded user exists
			if (!this.loadedCuriosity) return;

			axios
				.delete('', {
					params: {
						id: this.loadedCuriosity.id,
						action: 'deletecuriosity',
					},
					auth: {
						username: this.$store.state.chosenUsername,
						password: this.$store.state.chosenPassword,
					},
				})
				.then((response) => {
					// console.log('response:');
					// console.log(response);
					if (response.data.result === 'success') {
						// console.log('deleting was successful');
						this.$store.dispatch('fetchCuriosities');
						this.$router.replace('/list');
					}
				})
				.catch((err) => {
					console.log(err);
					return null;
				});
		},
	},
	mounted() {},
	created() {
		addIcons({
			'trash-outline': trashOutline,
			'pencil-outline': pencilOutline,
		});
	},
	ionViewDidEnter() {
		this.pageReady = true;
		this.$store.dispatch('setVueReady', true);
		// console.log('MarkerInfo page did enter');
	},
	ionViewDidLeave() {
		// console.log('MarkerInfo page did leave');
	},
	ionViewWillEnter() {
		// console.log('MarkerInfo page will enter');
	},
	ionViewWillLeave() {
		// console.log('MarkerInfo page will leave');
		this.pageReady = false;
		this.$store.dispatch('setVueReady', false);
	},
};
</script>

<style></style>
