<template>
	<ion-fab vertical="bottom" horizontal="end" slot="fixed">
		<ion-fab-button>
			<ion-icon class="md hydrated" :class="iconClass" name="add-outline"></ion-icon>
		</ion-fab-button>
	</ion-fab>
</template>

<script>
import { IonFab, IonFabButton, IonIcon } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { addOutline } from 'ionicons/icons';

export default {
	components: {
		IonFab,
		IonFabButton,
		IonIcon,
	},
	computed: {
		iconClass() {
			return this.rotation === '45' ? 'rotated' : '';
		},
	},
	props: ['rotation'],
	created() {
		addIcons({
			'add-outline': addOutline,
		});
	},
};
</script>

<style scoped>
ion-icon {
	transition: all 300ms ease;
}
ion-icon.rotated {
	transform: rotate(45deg);
}
</style>
