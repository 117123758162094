<template>
	<base-layout page-title="Aanpassen">
		<curiosity-form mode="edit" :curiosity="curiosityData" v-if="authorized"></curiosity-form>
		<unauthorized v-else-if="loadedCuriosity"></unauthorized>
	</base-layout>
</template>

<script>
import CuriosityForm from '../components/CuriosityForm';
import Unauthorized from '../components/Unauthorized';

export default {
	components: { CuriosityForm, Unauthorized },
	data() {
		return {
			curiosityData: null,
		};
	},
	computed: {
		authorized() {
			if (!this.loadedCuriosity) return false;
			if (!this.curiosityData) return false;
			if (this.loggedInUser.user_type === 'god' || this.loggedInUser.user_type === 'admin') return true;
			if (this.loggedInUser.id === this.loadedCuriosity.author) return true;
			return false;
		},
		loadedCuriosity() {
			return this.$store.getters.curiosity(this.$route.params.id);
		},
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
	ionViewDidLeave() {
		this.curiosityData = null;
	},
	ionViewWillEnter() {
		if (!this.loadedCuriosity) return;
		// Create a new object that holds the current data and is non-reactive
		// this.curiosityData = this.loadedCuriosity;
		this.curiosityData = { ...this.loadedCuriosity }; // Using the spread operator disables the reactivity it seems
		this.curiosityData.imageList = this.loadedCuriosity.files.map((file) => {
			return {
				id: file.id,
				path: this.$store.state.imageBase + '/small/',
				img: file.name,
				file: file,
				upload_perc: 100, // Percentage
				error: false,
				message: '',
			};
		});
	},
};
</script>
