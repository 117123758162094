<template>
	<form @submit.prevent="logIn">
		<ion-card>
			<ion-card-header>
				<ion-card-title>Aanmelden</ion-card-title>
			</ion-card-header>
			<ion-card-content>
				<ion-list>
					<ion-item>
						<ion-label position="floating">Gebruikersnaam</ion-label>
						<ion-input v-model="user" type="text" @ionChange="$store.commit('clearErrors')" @keyup.enter="logIn" required />
					</ion-item>
					<ion-item>
						<ion-label position="floating">Wachtwoord</ion-label>
						<ion-input v-model="pass" type="password" @ionChange="$store.commit('clearErrors')" @keyup.enter="logIn" required />
					</ion-item>
				</ion-list>
				<ion-button expand="block" type="submit">
					<ion-icon slot="start" name="log-in-outline"></ion-icon>
					Aanmelden
				</ion-button>
			</ion-card-content>
		</ion-card>
	</form>
	<app-errors></app-errors>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonList, IonItem, IonLabel, IonInput, IonIcon } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { logInOutline } from 'ionicons/icons';
import AppErrors from '../components/AppErrors';
export default {
	components: { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonList, IonItem, IonLabel, IonInput, IonIcon, AppErrors },
	data() {
		return {
			user: '', //'christoph',
			pass: '', //'test',
		};
	},
	methods: {
		logIn() {
			this.$store.state.chosenUsername = this.user;
			this.$store.state.chosenPassword = this.pass;

			if (this.user === '' || this.pass === '') {
				this.$store.commit('clearErrors');
				this.$store.commit('addError', 'Gebruikersnaam en wachtwoord mogen niet leeg zijn.');
				return;
			}

			this.$store.dispatch('logIn');
		},
	},
	created() {
		addIcons({
			'log-in-outline': logInOutline,
		});
	},
};
</script>

<style scoped>
form {
	/* width: 100%; */
	display: inline-block;
}
</style>
