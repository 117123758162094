import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import BaseLayout from './components/BaseLayout.vue';
// import GoogleMap from './components/GoogleMap.vue';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/core.css';
// import home from './views/Home';
const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(store);

app.mixin({
	methods: {
		formatDate(dateTime, include_time) {
			include_time = true;
			// Split timestamp (2020-12-25 15:56:28) into [ Y, M, D, h, m, s ]
			// eslint-disable-next-line no-unused-vars
			const [year, month, day, hour, minute, second] = dateTime.split(/[- :]/);
			let result = `${day}/${month}/${year}`;
			if (include_time) {
				// result += ` - ${hour}:${minute}:${second}`;
				result += ` - ${hour}:${minute}`;
			}
			return result;
		},
		leadingZero(input) {
			return parseInt(input) < 10 ? '0' + input : input;
		},
		testFunction() {
			console.log('test function inside main.js');
		},
		launchNavigationApp(app, lat = null, lng = null) {
			console.log('launch nav app fired');
			if (lat === null || lng === null) return;
			const destination = lat + ',' + lng;
			if (app === 'google-maps') window.open('https://www.google.com/maps/search/?api=1&query=' + destination);
			else window.open('https://waze.com/ul?ll=' + destination + '&navigate=yes&z=10'); // https://developers.google.com/waze/deeplinks
		},
	},
});

app.component('base-layout', BaseLayout);
// app.component('google-map', GoogleMap);

router.isReady().then(() => {
	app.mount('#app');
});

window.vm = {};
