import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../views/Home';
import AddUser from '../views/AddUser';
import AddCuriosity from '../views/AddCuriosity';
import EditUser from '../views/EditUser';
import EditCuriosity from '../views/EditCuriosity';
import UserList from '../views/UserList';
import UserDetails from '../views/UserDetails';
import About from '../views/About';
import MarkerList from '../views/MarkerList';
import MarkerDetails from '../views/MarkerDetails';
import Account from '../views/Account';

const routes = [
	{
		path: '/',
		redirect: '/home',
		// component: Home,
	},
	{
		path: '/home',
		component: Home,
	},
	{
		path: '/list',
		component: MarkerList,
	},
	{
		path: '/list/:id',
		component: MarkerDetails,
	},
	{
		path: '/list/add',
		component: AddCuriosity,
	},
	{
		path: '/edit/:id',
		component: EditCuriosity,
	},
	{
		path: '/users',
		component: UserList,
	},
	{
		path: '/users/add',
		component: AddUser,
	},
	{
		path: '/users/:id',
		component: UserDetails,
	},
	{
		path: '/edit-user/:id',
		component: EditUser,
	},
	{
		path: '/about',
		component: About,
	},
	{
		path: '/account',
		component: Account,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
