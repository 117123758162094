<template>
	<form v-if="curiosityData" @submit.prevent="submitForm" :disabled="formDisabled ? 'true' : ''">
		<fieldset ref="myFieldset">
			<!-- {{ curiosityData.id }} || {{ curiosityData.id }} -->
			<ion-list>
				<ion-item :disabled="formDisabled">
					<ion-label position="floating">Titel<span class="required"></span></ion-label>
					<ion-input clear-input v-model="curiosityData.title" type="text" required></ion-input>
				</ion-item>
				<ion-item :disabled="formDisabled">
					<ion-label position="floating">Omschrijving</ion-label>
					<ion-textarea v-model="curiosityData.description" cols="40" rows="5"></ion-textarea>
				</ion-item>
				<ion-item :disabled="formDisabled" v-if="curiosityData.approved !== undefined && loggedInUser && (loggedInUser.user_type == 'god' || loggedInUser.user_type == 'admin')">
					<ion-label>Actief</ion-label>
					<ion-toggle :checked="curiosityData.approved == 1" @ionChange="curiosityData.approved = $event.target.checked ? 1 : 0">Actief</ion-toggle>
				</ion-item>
				<ion-item :disabled="formDisabled" lines="none">
					<ion-label>Foto's (max. 25MB)</ion-label>
					<input type="file" style="display: none;" @change="addMoreImages" ref="inputImages" multiple="multiple" accept="image/png, image/jpeg" />
				</ion-item>
				<image-grid :items="curiosityData.imageList" :locked="formDisabled" @delete="deleteImage"></image-grid>
				<ion-button :disabled="formDisabled" size="default" @click="$refs.inputImages.click()" class="ion-float-right">Selecteer Foto's</ion-button>
			</ion-list>
			<app-errors>{{ error }}</app-errors>
			<ion-button type="submit" :disabled="formDisabled">{{ mode === 'add' ? 'Toevoegen' : 'Aanpassen' }}</ion-button>
			<ion-button ref="buttonCancel" @click="goBack" :disabled="formDisabled">Annuleer</ion-button>
		</fieldset>
	</form>
</template>

<script>
import axios from 'axios';
import { IonButton, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle } from '@ionic/vue';
import AppErrors from '../components/AppErrors';
import ImageGrid from '../components/ImageGrid';

export default {
	components: { IonButton, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, AppErrors, ImageGrid },
	data() {
		return {
			formDisabled: false,
			imageCounter: 0,
			curiosityData: {
				id: '',
				title: '',
				description: '',
				imageList: [],
			},
			imagesToDelete: [],
		};
	},
	props: {
		curiosity: {
			default: null,
		},
		mode: {
			default: 'add', // add or edit
		},
	},
	watch: {
		curiosity: {
			immediate: true, // This will execute no initial page load
			deep: true, // This will let Vue know to look inside the array
			handler: function(val) {
				if (!val) return;
				this.curiosityData = val;
			},
		},
	},
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
		addNewMarkerPosition() {
			return this.$store.getters.addNewMarkerPosition;
		},
		uploadQueue() {
			return this.curiosityData.imageList.filter((item) => parseInt(item.upload_perc) < 100 && !item.error);
		},
	},
	methods: {
		goBack() {
			if (this.mode === 'edit') this.$router.replace('/list/' + this.curiosity.id);
			else this.$router.push('/list');
		},
		addMoreImages(event) {
			console.log('add images...');
			let data = this.curiosityData;
			event.target.files.forEach((file) => {
				console.log(file);
				// const tooLarge = file.size > 20971520; // 20MB
				const tooLarge = file.size > 26214400; // 25MB
				const wrongType = file.type !== 'image/png' && file.type !== 'image/jpeg';
				let errorMessage = '';
				if (wrongType) errorMessage = 'Ongeldig bestand.';
				else if (tooLarge) errorMessage = 'Afbeelding te groot.';

				data.imageList.unshift({
					id: 'new-' + this.imageCounter,
					path: '',
					img: wrongType ? 'invalid' : URL.createObjectURL(file),
					file: file,
					upload_perc: 0, // Percentage
					error: tooLarge || wrongType,
					message: errorMessage,
				});
				this.imageCounter++;
			});
			this.$refs.inputImages.value = ''; // Reset the file input
			// console.log(this.defaultData);
			this.curiosityData = data;
			console.log(this.curiosityData);
		},
		submitForm() {
			this.formDisabled = true;
			console.log('images to delete: ', ...this.imagesToDelete.map((id) => parseInt(id)));

			if (this.imagesToDelete.length > 0) this.$store.dispatch('deleteFiles', this.imagesToDelete);

			if (this.uploadQueue.length > 0) {
				this.uploadAllImages();
			} else {
				this.formDisabled = false;
				this.sendForm();
			}
		},
		uploadAllImages() {
			const promises = [];
			this.uploadQueue.forEach((item, index) => {
				console.log(index);
				const formData = new FormData();
				formData.append('file', item.file);

				// Get the index inside the curiosityData.imageList array that matches the id
				const listIndex = this.getFileIndexById(item.id);

				promises.push(
					axios
						.post('', formData, {
							headers: {
								'Content-Type': 'multipart/form-data',
							},
							params: {
								action: 'addfile',
							},
							auth: {
								username: this.$store.state.chosenUsername,
								password: this.$store.state.chosenPassword,
							},
							onUploadProgress: (progressEvent) => {
								const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
								// console.log(progressEvent);
								// console.log(percentCompleted);

								// console.log(listIndex);

								if (typeof this.curiosityData.imageList[listIndex] !== 'undefined') this.curiosityData.imageList[listIndex].upload_perc = percentCompleted;
							},
						})
						.then((response) => {
							// console.log('response for image ' + item.id);
							// console.log(response);
							// Set the id to the new DB id
							if (typeof this.curiosityData.imageList[listIndex] !== 'undefined' && response.data.id !== '' && response.data.id !== 'undefined') this.curiosityData.imageList[listIndex].id = response.data.id;
						})
						.catch((err) => {
							console.log(err);
							return null;
						})
				);
			});

			Promise.all(promises).then(() => {
				console.log('all done');
				console.log('upload queue');
				console.log(this.uploadQueue);
				this.formDisabled = false;
				this.sendForm();
			});
		},
		sendForm() {
			// Form Action (add / edit)
			const formAction = this.mode === 'add' ? 'addcuriosity' : 'editcuriosity';

			// Data
			let formData = new FormData();
			for (let key in this.curiosityData) {
				formData.append(key, this.curiosityData[key]);
			}
			if (this.curiosityData) formData.append('id', this.curiosityData.id);
			if (!this.curiosity) {
				if (this.addNewMarkerPosition) {
					formData.append('lat', this.addNewMarkerPosition.lat);
					formData.append('lng', this.addNewMarkerPosition.lng);
				}
			}
			const filteredImageList = this.curiosityData.imageList.filter((item) => item.error === false && item.id !== '');
			formData.append(
				'imageIds',
				// this.curiosityData.imageList.map((item) => item.id)
				filteredImageList.map((item) => item.id)
			);

			// Ajax / Axios
			axios
				.post('', formData, {
					params: {
						action: formAction,
					},
					auth: {
						username: this.$store.state.chosenUsername,
						password: this.$store.state.chosenPassword,
					},
				})
				.then((response) => {
					if (response.data.result === 'success') {
						/*
						this.$store.dispatch('fetchCuriosities');
						if (this.mode === 'edit') this.$router.push('/list/' + this.curiosityData.id);
						else this.$router.push('/list/' + response.data.new_id);
						*/
						this.$store.dispatch('fetchCuriosities').then(() => {
							if (this.mode === 'edit') this.$router.replace('/list/' + this.curiosityData.id);
							else this.$router.replace('/list/' + response.data.new_id);
						});
					} else {
						// if (typeof response.data.message !== undefined) this.errors.push(response.data.message);
					}
				})
				.catch((err) => {
					console.log(err);
					return null;
				});
		},
		getFileIndexById(id) {
			const index = this.curiosityData.imageList.findIndex((item) => {
				return item.id === id;
			});
			return index;
		},
		deleteImage(id, upload_perc) {
			if (upload_perc >= 100) this.imagesToDelete.push(id); // Only store the uploaded images
			const index = this.getFileIndexById(id);
			if (index !== -1) this.curiosityData.imageList.splice(index, 1);
		},
	},
};
</script>

<style scoped></style>
