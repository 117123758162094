<template>
	<base-layout page-title="Overzicht" title-button-type="menu-toggle">
		<ion-list lines="full">
			<ion-item v-for="curiosity in curiosities" :key="curiosity.id" :router-link="`/list/${curiosity.id}`" button>
				<ion-label>
					<h2>
						{{ curiosity.title }} <span class="date">- {{ formatDate(curiosity.date_added) }}</span>
					</h2>
					<h3>{{ curiosity.description }}</h3>
					<h4>{{ formatDate(curiosity.date_added) }}</h4>
				</ion-label>
				<div v-if="curiosity.approved" slot="end">
					<ion-text v-if="curiosity.approved == 1" color="success">Actief</ion-text>
					<ion-text v-else color="danger">Inactief</ion-text>
					<!-- <ion-text v-if="curiosity.approved == 0" color="danger">Nog goed te keuren</ion-text> -->
				</div>
			</ion-item>
		</ion-list>
	</base-layout>
</template>

<script>
import { IonList, IonItem, IonText, IonLabel } from '@ionic/vue';
export default {
	components: {
		IonList,
		IonItem,
		IonText,
		IonLabel,
	},
	computed: {
		curiosities() {
			return this.$store.getters.curiosities;
		},
	},
};
</script>

<style scoped>
h2 {
	font-weight: 500;
}
h4 {
	display: none;
}
.date {
	font-size: 0.8em;
}
</style>
