<template>
	<base-layout pageTitle="Account" title-button-type="menu-toggle">
		<div v-if="loggedInUser">
			<user-info :user="loggedInUser"></user-info>
			<logout-form></logout-form>
		</div>
		<center-page v-else>
			<login-form></login-form>
		</center-page>
	</base-layout>
</template>

<script>
import CenterPage from '../components/CenterPage.vue';
import LoginForm from '../components/LoginForm.vue';
import LogoutForm from '../components/LogoutForm.vue';
import UserInfo from '../components/UserInfo.vue';
export default {
	components: { LoginForm, LogoutForm, CenterPage, UserInfo },
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
};
</script>

<style scoped></style>
