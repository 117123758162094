<template>
	<ion-card v-if="user" class="ion-no-margin ion-margin-bottom">
		<!-- <div>
			<img src="/assets/shapes.svg" />
		</div> -->
		<ion-card-header class="ion-no-padding ion-margin-bottom">
			<!-- <img src="/assets/shapes.svg" /> -->
			<!-- <ion-card-title>{{ user.user_nicename }}</ion-card-title>
			<ion-card-subtitle>{{ user.user_nicename }}</ion-card-subtitle> -->
		</ion-card-header>

		<ion-card-content>
			<ion-item lines="full">
				<ion-label slot="start">Naam</ion-label>
				<ion-text slot="end">{{ user.user_nicename }}</ion-text>
			</ion-item>
			<ion-item lines="full" v-if="user.user_login">
				<ion-label>Gebruikersnaam</ion-label>
				<ion-text slot="end">{{ user.user_login }}</ion-text>
			</ion-item>
			<ion-item lines="full">
				<ion-label>E-Mail</ion-label>
				<ion-text slot="end"
					><a :href="'mailto:' + user.user_email">{{ user.user_email }}</a></ion-text
				>
			</ion-item>
			<ion-item lines="full">
				<ion-label>Voertuigen</ion-label>
				<ion-text slot="end">{{ user_curiosities.length }}</ion-text>
			</ion-item>
			<ion-item lines="full">
				<ion-label>Geregistreerd</ion-label>
				<ion-text slot="end">{{ formatDate(user.user_registered) }}</ion-text>
			</ion-item>
			<ion-item lines="full">
				<ion-label>Laatst Online</ion-label>
				<ion-text slot="end">{{ formatDate(user.user_last_online) }}</ion-text>
			</ion-item>
			<ion-item v-if="user_notes" lines="full">
				<!-- <ion-item v-if="user.user_notes != undefined" lines="full"> -->
				<ion-label slot="start" class="ion-align-self-start">Notities</ion-label>
				<ion-text class="multiline ion-padding-top ion-padding-bottom" slot="end">{{ user_notes }}</ion-text>
			</ion-item>
			<ion-item v-if="user.user_type" lines="full">
				<ion-label>Type</ion-label>
				<ion-text class="ion-text-capitalize" slot="end">{{ user.user_type }}</ion-text>
			</ion-item>
			<ion-item v-if="user.user_active" lines="full">
				<ion-label>Status</ion-label>
				<ion-text v-if="user.user_active == 1" color="success" slot="end">Actief</ion-text>
				<ion-text v-else color="danger" slot="end">Inactief</ion-text>
			</ion-item>
		</ion-card-content>
	</ion-card>
</template>

<script>
import { IonCard, IonItem, IonLabel, IonText } from '@ionic/vue';
export default {
	components: {
		IonCard,
		IonItem,
		IonLabel,
		IonText,
	},
	props: ['user'],
	computed: {
		user_notes() {
			return this.user.user_notes === '' ? '-' : this.user.user_notes;
			// return '';
		},
		user_curiosities() {
			if (!this.user) return;
			return this.$store.getters.curiositiesByUserId(this.user.id);
		},
	},
};
</script>

<style scoped>
ion-item {
	--padding-start: 0px;
	--inner-padding-start: 0px;
	--padding-end: 0px;
	--inner-padding-end: 0px;
}
.item-native {
	flex-direction: column;
}
ion-label {
	font-weight: 500;
}
ion-text {
	text-align: right;
}
ion-card-header {
	/* height: 75px; */
	/* height: 150px; */
	width: 100%;
	/* background-image: url('/assets/shapes.svg'); */
	/* background-size: cover; */
	/* background-repeat: repeat; */
	/* background-image: url('/assets/avatar.svg'); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left 10px top;
	/* background-color: #c5dbff; */

	/* background: rgb(197, 219, 255); */
	/* background: linear-gradient(-90deg, rgba(197, 219, 255, 1) 30%, rgba(56, 128, 255, 1) 70%); */

	/* background: none; */
	border-top: 5px solid var(--ion-color-primary);
}
</style>
