<template>
	<ion-card v-if="curiosity" class="ion-no-margin ion-margin-bottom">
		<ion-card-header id="map-holder" class="ion-no-padding">
			<google-map :curiosities="new Array(curiosity)" map-mode="details" :key="'details-' + thekey + '-' + curiosity.id"></google-map>
		</ion-card-header>
		<ion-card-content>
			<ion-item class="item-native">
				<div class="ion-padding-vertical">
					<ion-label :class="{ 'ion-margin-bottom': curiosity.description.length > 0 }">{{ curiosity.title }}</ion-label>
					<ion-text class="multiline">{{ curiosity.description }}</ion-text>
				</div>
			</ion-item>
			<ion-item lines="full">
				<ion-label>Toegevoegd</ion-label>
				<ion-text slot="end">{{ formatDate(curiosity.date_added) }}</ion-text>
			</ion-item>
			<ion-item lines="full">
				<ion-label slot="start">Auteur</ion-label>
				<ion-text slot="end">
					<router-link :to="'/users/' + curiosity.author" v-if="authorName">
						{{ authorName }}
					</router-link>
				</ion-text>
			</ion-item>
			<ion-item v-if="curiosity.approved" lines="none">
				<ion-label>Status</ion-label>
				<ion-text v-if="curiosity.approved == 1" color="success" slot="end">Actief</ion-text>
				<ion-text v-else color="danger" slot="end">Inactief</ion-text>
			</ion-item>
			<ion-item v-if="curiosity.approved == 0" lines="none">
				<ion-text color="danger"><strong>Opgelet: </strong>U moet wachten op goedkeuring voordat dit voor anderen zichtbaar is.</ion-text>
			</ion-item>
			<image-grid :items="imageList" mode="details" />
			<ion-item lines="none">
				<div slot="end" class="ion-padding-top">
					<ion-button size="default" @click="launchNavigationApp('google-maps', curiosity.lat, curiosity.lng)">
						<ion-icon slot="start" src="/assets/map-launchers/map-marked-alt-solid.svg"></ion-icon>
						Google Maps
					</ion-button>
					<ion-button size="default" @click="launchNavigationApp('waze', curiosity.lat, curiosity.lng)">
						<ion-icon slot="start" src="/assets/map-launchers/waze-brands-filled.svg"></ion-icon>
						Waze
					</ion-button>
				</div>
			</ion-item>
		</ion-card-content>
	</ion-card>
</template>

<script>
import { IonCard, IonItem, IonLabel, IonText } from '@ionic/vue';
import GoogleMap from '@/components/GoogleMap';
import ImageGrid from './ImageGrid.vue';

export default {
	components: {
		IonCard,
		IonItem,
		IonLabel,
		IonText,
		GoogleMap,
		ImageGrid,
	},
	props: ['curiosity'],
	computed: {
		imageList() {
			return this.curiosity.files.map((file) => {
				return {
					id: file.id,
					path: this.$store.state.imageBase + '/small/',
					img: file.name,
					error: false,
				};
			});
		},
		authorName() {
			const user = this.$store.getters.user(this.curiosity.author);
			return user ? user.user_nicename : '';
		},
	},
	data() {
		return {
			thekey: 1,
		};
	},
};
</script>

<style scoped>
ion-item {
	--padding-start: 0px;
	--inner-padding-start: 0px;
	--padding-end: 0px;
	--inner-padding-end: 0px;
}
.item-native {
	flex-direction: column;
}
ion-label {
	font-weight: 500;
}
/* ion-text {
	text-align: right;
} */
ion-card-header {
	background: none;
	/* border-top: 5px solid #3880ff; */
}
#map-holder {
	width: 100%;
	/* height: 400px; */
	height: 50vh;
	/* height: 250vh; */
	min-height: 200px;
	position: relative;
}
</style>
