<template>
	<base-layout :pageTitle="pageTitle" default-back="/users">
		<div v-if="loadedUser">
			<user-info :user="loadedUser"></user-info>
			<ion-button v-if="loggedInUser.user_type === 'god' || loggedInUser.user_type === 'admin' || (loadedUser && loggedInUser.id === loadedUser.id)" :router-link="`/edit-user/${loadedUser.id}`">
				<ion-icon slot="start" name="pencil-outline"></ion-icon>
				Aanpassen
			</ion-button>
			<ion-button v-if="loggedInUser.user_type === 'god' || loggedInUser.user_type === 'admin'" @click="deleteUser">
				<ion-icon slot="start" name="trash-outline"></ion-icon>
				Verwijderen
			</ion-button>
		</div>
		<center-page v-else-if="pageReady">Deze eigenaar kon niet gevonden worden.</center-page>
	</base-layout>
</template>

<script>
import axios from 'axios';
import BaseLayout from '@/components/BaseLayout.vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { addIcons } from 'ionicons';
import { pencilOutline, trashOutline } from 'ionicons/icons';
import UserInfo from '../components/UserInfo';
import CenterPage from '../components/CenterPage';

export default {
	components: {
		BaseLayout,
		IonButton,
		IonIcon,
		UserInfo,
		CenterPage,
	},
	data() {
		return {
			pageReady: false,
		};
	},
	computed: {
		loadedUser() {
			return this.$store.getters.user(this.userId);
		},
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
		userId() {
			return this.$route.params.id;
		},
		pageTitle() {
			if (this.loadedUser) return this.loadedUser.user_nicename;
			if (!this.pageReady) return '';
			// if (!this.loadedUser) return 'Onbekend';
			return 'Onbekend';
		},
	},
	methods: {
		deleteUser() {
			// TODO: Confirmation message / popup / modal

			// Check if loaded user exists
			if (!this.loadedUser) alert('no!!!');

			axios
				.delete('', {
					params: {
						id: this.loadedUser.id,
						action: 'deleteuser',
					},
					auth: {
						username: this.$store.state.chosenUsername,
						password: this.$store.state.chosenPassword,
					},
				})
				.then((response) => {
					// console.log('response:');
					// console.log(response);
					if (response.data.result === 'success') {
						// console.log('deleting was successful');
						this.$store.dispatch('fetchUsers');
						this.$router.replace('/users');
					}
				})
				.catch((err) => {
					console.log(err);
					return null;
				});
		},
	},
	created() {
		addIcons({
			'trash-outline': trashOutline,
			'pencil-outline': pencilOutline,
		});
	},
	ionViewDidEnter() {
		this.pageReady = true;
	},
	ionViewWillLeave() {
		this.pageReady = false;
	},
};
</script>

<style></style>
