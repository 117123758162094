import { createStore } from 'vuex';
import axios from 'axios';

// import { Plugins } from '@capacitor/core';
// const { Geolocation } = Plugins;
// import { Geolocation } from '@capacitor/core';

const APP_IS_ON_LOCALHOST = window.location.href.indexOf('tim.vu') === -1;

// Axios Config
axios.defaults.baseURL = APP_IS_ON_LOCALHOST ? 'http://192.168.68.100/xampp-Projects/Teknon/api/api.php' : 'https://teknon.tim.vu/api/api.php';

const store = createStore({
	state() {
		return {
			loadingStatus: 'notloading',
			locatingStatus: '',
			users: [],
			loggedInUser: false,
			chosenUsername: '',
			chosenPassword: '',
			errors: [],
			curiosities: [],
			currentPosition: null,
			addNewMarkerPosition: null,
			googleMapScriptLoaded: false,
			vueReady: false,
			mapOptions: null,
			googleApiKey: 'AIzaSyDBA_SKtbbnkfLJiGzOlP2UzDNA4LmS8KE',
			imageBase: APP_IS_ON_LOCALHOST ? 'http://192.168.68.100/xampp-Projects/Teknon/uploads' : 'https://teknon.tim.vu/uploads',
		};
	},
	getters: {
		googleApiKey(state) {
			return state.googleApiKey;
		},
		users(state) {
			return state.users;
		},
		user(state) {
			return (id) => {
				return state.users.find((user) => user.id === id);
			};
		},
		loggedInUser(state) {
			return state.loggedInUser;
		},
		errors(state) {
			return state.errors;
		},
		curiosities(state) {
			return state.curiosities;
		},
		curiosity(state) {
			return (id) => {
				return state.curiosities.find((curiosity) => curiosity.id === id);
			};
		},
		curiosityIds(state) {
			return state.curiosities.map((curiosity) => curiosity.id);
		},
		curiositiesByUserId(state) {
			return (userId) => {
				return state.curiosities.filter((curiosity) => curiosity.author === userId);
			};
		},
		currentPosition(state) {
			return state.currentPosition;
		},
		addNewMarkerPosition(state) {
			return state.addNewMarkerPosition;
		},
		googleMapOptions(state) {
			return state.mapOptions;
		},
		googleMapScriptLoaded(state) {
			return state.googleMapScriptLoaded;
		},
		vueReady(state) {
			return state.vueReady;
		},
		locatingStatus(state) {
			return state.locatingStatus;
		},
		locatingStatusText(state) {
			if (state.locatingStatus === '') return 'Localiseer';
			else if (state.locatingStatus === 'LOADING') return 'Bezig met localiseren...';
			else if (state.locatingStatus === 'FAILED') return 'Kon niet localiseren. Probeer opnieuw.';
		},
	},
	mutations: {
		add(state, n) {
			state.users.push(n);
		},
		setLoadingStatus(state, status) {
			state.loadingStatus = status;
		},
		setUsers(state, users) {
			state.users = users;
		},
		setLoggedInUser(state, userinfo) {
			state.loggedInUser = userinfo;
			// state.loggedInUser = state.users.find((user) => user.id === userinfo.id);
		},
		setCuriosities(state, curiosities) {
			state.curiosities = curiosities;
		},
		setCurrentPosition(state, position) {
			if (typeof position.coords !== 'undefined') {
				// GeoLocation
				position.coords.lat = position.coords.latitude;
				position.coords.lng = position.coords.longitude;
				state.currentPosition = position.coords;
			} else {
				// Manual coords eg from dragging the cursor
				state.currentPosition = position;
			}
		},
		setNewMarkerPosition(state, position) {
			state.addNewMarkerPosition = position;
		},
		setVueReady(state, value) {
			state.vueReady = value;
		},
		setGoogleMapOptions(state, value) {
			state.mapOptions = value;
		},
		addError(state, message) {
			state.errors.push(message);
		},
		clearErrors(state) {
			state.errors = [];
		},
		addCuriosity(state, marker) {
			state.curiosities.push(marker);
		},
		setLocatingStatus(state, status) {
			state.locatingStatus = status;
		},
	},
	actions: {
		fetchUsers(context) {
			// console.log('fetching users...');
			context.commit('setLoadingStatus', 'loading');
			axios
				.get('', {
					params: {
						action: 'getusers',
					},
					auth: {
						username: context.state.chosenUsername,
						password: context.state.chosenPassword,
					},
				})
				.then(function(response) {
					// console.log(response);
					context.commit('setLoadingStatus', 'notloading');
					context.commit('setUsers', response.data.users);
				})
				.catch((error) => console.log(error));
		},
		deleteFiles(context, ids) {
			axios
				.delete('', {
					params: {
						ids: ids,
						action: 'deletefiles',
					},
					auth: {
						username: context.state.chosenUsername,
						password: context.state.chosenPassword,
					},
				})
				.then(function(response) {
					console.log(response);
				})
				.catch((error) => console.log(error));
		},
		fetchCuriosities(context) {
			// console.log('fetching users...');
			context.commit('setLoadingStatus', 'loading');
			axios
				.get('', {
					params: {
						action: 'getcuriosities',
					},
					auth: {
						username: context.state.chosenUsername,
						password: context.state.chosenPassword,
					},
				})
				.then(function(response) {
					// console.log(response);
					context.commit('setLoadingStatus', 'notloading');
					context.commit('setCuriosities', response.data.curiosities);
				})
				.catch((error) => console.log(error));
		},
		logIn(context) {
			if (context.state.chosenUsername === '' || context.state.chosenPassword === '') return null;
			// Data
			let formData = new FormData();
			formData.append('user', context.state.chosenUsername);
			formData.append('pass', context.state.chosenPassword);
			// Ajax / Axios
			axios
				.post('', formData, {
					params: {
						action: 'login',
					},
					auth: {
						username: context.state.chosenUsername,
						password: context.state.chosenPassword,
					},
				})
				.then((response) => {
					// console.log(response);
					if (response.data.result === 'success') {
						const logged_in_user = response.data.logged_in_user;
						if (typeof logged_in_user !== undefined) {
							context.commit('setLoggedInUser', logged_in_user);
						} else {
							context.commit('setLoggedInUser', false);
						}
					} else {
						context.state.chosenUsername = '';
						context.state.chosenPassword = '';
						context.commit('clearErrors');
						if (typeof response.data.message !== undefined) context.commit('addError', response.data.message);
						if (typeof response.data.message !== undefined) console.log(response.data.message);
					}
					context.dispatch('fetchUsers');
				})
				.catch((err) => {
					console.log(err);
					return null;
				});
		},
		logOut(context) {
			context.commit('setLoggedInUser', false);
			context.dispatch('fetchUsers');
		},
		setNewMarkerPosition(context, coordinates) {
			if (coordinates === 'MAP_CENTER') {
				const center = window.vm.mapVue.map.getCenter();
				context.commit('setCurrentPosition', { lat: center.lat(), lng: center.lng() });
			} else if (coordinates === 'NULL') {
				context.commit('setNewMarkerPosition', null);
			} else {
				context.commit('setNewMarkerPosition', coordinates);
			}
		},
		getCurrentPosition(context) {
			context.commit('setLocatingStatus', 'LOADING');
			if (!navigator.geolocation) {
				console.log('Geolocation is not supported by your browser');
				// context.dispatch('setNewMarkerPosition', 'MAP_CENTER');
				context.dispatch('getGoogleGeolocation');
			} else {
				console.log('Locating…');
				navigator.geolocation.getCurrentPosition(
					(geolocation) => {
						// context.commit('setCurrentPosition', coordinates);
						// console.log(coordinates);
						context.dispatch('setNewMarkerPosition', { lat: geolocation.coords.latitude, lng: geolocation.coords.longitude });
						context.commit('setLocatingStatus', '');
					},
					(err) => {
						console.error(err); // TypeError: failed to fetch
						console.error(err.message); // TypeError: failed to fetch
						// context.dispatch('setNewMarkerPosition', 'MAP_CENTER');
						context.dispatch('getGoogleGeolocation');
					},
					{ timeout: 5000, enableHighAccuracy: true }
				);
			}
		},
		getGoogleGeolocation(context) {
			axios
				.post(
					'https://www.googleapis.com/geolocation/v1/geolocate',
					{},
					{
						params: {
							key: context.getters.googleApiKey,
						},
					}
				)
				.then((response) => {
					console.log(response);

					context.dispatch('setNewMarkerPosition', { lat: response.data.location.lat, lng: response.data.location.lng });
					context.commit('setLocatingStatus', '');
				})
				.catch((err) => {
					console.log(err);
					context.commit('setLocatingStatus', 'FAILED');
				});
		},
		setCurrentPosition(context, coordinates) {
			context.commit('setCurrentPosition', coordinates);
		},
		setVueReady(context, readyOrNot) {
			context.commit('setVueReady', readyOrNot);
		},
	},
});

export default store;
