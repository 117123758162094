<template>
	<base-layout page-title="Gebruiker Toevoegen" default-back="/users">
		<user-form mode="add" v-if="loggedInUser.user_type === 'god' || loggedInUser.user_type === 'admin'" :key="uniqueKey"></user-form>
		<unauthorized v-else></unauthorized>
	</base-layout>
</template>

<script>
import UserForm from '../components/UserForm';
import Unauthorized from '../components/Unauthorized';

export default {
	data() {
		return {
			uniqueKey: null,
		};
	},
	components: { UserForm, Unauthorized },
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
	ionViewWillEnter() {
		console.log('entering add user');
		this.uniqueKey = Date.now();
	},
};
</script>

<style></style>
