<template>
	<base-layout page-title="Gebruikersprofiel Aanpassen">
		<user-form mode="edit" :user="userData" v-if="authorized"></user-form>
		<unauthorized v-else-if="loadedUser"></unauthorized>
	</base-layout>
</template>

<script>
import UserForm from '../components/UserForm';
import Unauthorized from '../components/Unauthorized';

export default {
	components: {
		UserForm,
		Unauthorized,
	},
	data() {
		return {
			userData: null,
		};
	},
	computed: {
		authorized() {
			if (!this.loadedUser) return false;
			if (!this.userData) return false;
			if (this.loggedInUser.user_type === 'god' || this.loggedInUser.user_type === 'admin') return true;
			if (this.loggedInUser.id === this.loadedUser.id) return true;
			return false;
		},
		loadedUser() {
			return this.$store.getters.user(this.$route.params.id);
		},
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
	ionViewDidLeave() {
		this.userData = null;
	},
	ionViewWillEnter() {
		if (!this.loadedUser) return;
		// Create a new object that holds the current data and is non-reactive
		// this.userData = this.loadedUser;
		this.userData = { ...this.loadedUser }; // Using the spread operator disables the reactivity it seems
		this.userData.user_pass = '$dummyteknondummy$';
	},
};
</script>

<style></style>
