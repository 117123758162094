<template>
	<ion-app>
		<ion-split-pane content-id="main">
			<!--  the side menu - Available options: "overlay", "reveal", "push" -->
			<main-menu type="overlay"> </main-menu>

			<!-- the main content -->
			<ion-router-outlet id="main" />
		</ion-split-pane>
		<!-- <div class="maptest"><google-map :curiosities="curiosities" key="home"></google-map></div> -->
		<!-- <div class="maptest"><div id="map2"></div></div> -->
	</ion-app>
</template>

<script>
import { IonApp, IonSplitPane, IonRouterOutlet, useBackButton, useIonRouter } from '@ionic/vue';
import MainMenu from './components/MainMenu';
import { Plugins } from '@capacitor/core';
// eslint-disable-next-line no-unused-vars
// import { Loader } from '@googlemaps/js-api-loader';

const { App } = Plugins;

export default {
	name: 'App',
	components: {
		IonApp,
		IonSplitPane,
		IonRouterOutlet,
		MainMenu,
	},
	computed: {
		curiosities() {
			return this.$store.getters.curiosities;
		},
		googleApiKey() {
			return this.$store.getters.googleApiKey;
		},
	},
	data() {
		return {
			mainTimer: null,
		};
	},
	beforeMount() {
		this.loadGoogleMapsScript();
	},
	mounted() {
		this.fetchThings();
		this.mainTimer = setInterval(() => {
			this.fetchThings();
		}, 5000);
		// Load Google Maps Script
		/*
		this.GoogleMapsLoader = new Loader({
			apiKey: 'AIzaSyDBA_SKtbbnkfLJiGzOlP2UzDNA4LmS8KE',
			// version: 'weekly',
			version: 'quarterly',
		});
		this.GoogleMapsLoader.load()
			.then(() => {
				// console.log('Loader Finished');
				// this.loaded = true;
				this.$store.state.googleMapScriptLoaded = true;
			})
			.catch((error) => {
				console.log(error);
				this.feedback += 'error: ' + error + '<br />';
				// this.addGoogleMapsScript();
			});
		*/
	},
	beforeUnmount() {
		clearInterval(this.mainTimer);
	},
	methods: {
		loadGoogleMapsScript() {
			if (typeof window.google === 'undefined') {
				// console.log('> Adding Google Maps Script');
				const gmapScript = document.createElement('script');
				gmapScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.googleApiKey + '&callback=initMap';
				gmapScript.defer = true;
				gmapScript.async = true;
				gmapScript.onload = () => {
					// console.log('> Google Maps Script Loaded');
					// this.initMap();
				};

				gmapScript.onerror = () => {
					console.log('>>>> Error while loading Google Maps Script!');
					setTimeout(this.loadGoogleMapsScript, 1000);
				};

				window.initMap = () => {
					// console.log('> Google Maps Callback Fired');
					this.$store.state.mapOptions = {
						center: { lat: 50.973295, lng: 3.9760832 },
						zoom: 8, // 1: World, 5: Landmass/continent, 10: City, 15: Streets, 20: Buildings
						streetViewControl: false,
						// zoomControl: true,
						zoomControlOptions: {
							position: window.google.maps.ControlPosition.LEFT_CENTER,
						},
						// controlSize: 35,
						// gestureHandling: this.mapMode === 'details' ? 'cooperative' : 'auto',
						mapTypeControl: false,
						// noClear: true, // Not sure what this does
						// rotateControl: true,
					};
					this.$store.state.googleMapScriptLoaded = true;
				};

				document.head.appendChild(gmapScript);
				// document.body.appendChild(gmapScript);

				// document.addEventListener('DOMContentLoaded', () => {
				// 	this.feedback += 'DOMContentLoaded<br />';
				// 	// this.initMap();
				// });
			} else {
				console.log('> Google Maps Script Already Loaded');
			}
		},
		fetchThings() {
			this.$store.dispatch('fetchUsers');
			this.$store.dispatch('fetchCuriosities');
			this.$store.dispatch('logIn');
		},
		// testFunction() {
		// 	console.log('test function inside app.vue');
		// },
	},
	setup() {
		// Enable the hardware back button!
		const ionRouter = useIonRouter();
		useBackButton(-1, () => {
			if (!ionRouter.canGoBack()) {
				App.exitApp();
			}
		});
	},
};
</script>

<style scoped>
ion-split-pane {
	--border: none !important;
	border: none !important;
}

.feedback {
	position: fixed;
	left: 0;
	width: 100%;
	bottom: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 999;
	max-height: 200px;
	padding: 5px;
	color: #fff;
}
</style>
