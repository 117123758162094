<template>
	<base-layout page-title="App-Informatie" title-button-type="menu-toggle">
		<center-page>
			<div class="item">
				<img src="/assets/icon/teknon-icon.png" />
			</div>
			<div class="item">
				<div class="title">App ID</div>
				<div><strong>Teknon</strong></div>
			</div>
			<div class="item">
				<div class="title">Versie</div>
				<div>1.1.3 beta</div>
			</div>
			<div class="item">
				<div class="title">Laatste Update</div>
				<div>27/02/2021</div>
			</div>
			<div class="item">
				<div class="title">App-Ontwikkeling</div>
				<div><strong>Oversa Designs</strong></div>
				<div>
					<a href="mailto:info@oversa.be">info@oversa.be</a><br />
					<a href="https://www.oversa.be/" target="_blank">www.oversa.be</a>
				</div>
			</div>
			<div class="item">
				<div class="title">Copyright</div>
				<div>Copyright &copy; - Teknon</div>
			</div>
		</center-page>
	</base-layout>
</template>

<script>
import CenterPage from '../components/CenterPage';
export default {
	components: { CenterPage },
};
</script>

<style scoped>
ion-img,
img {
	display: inline-block;
	width: 100px;
}
.item {
	margin-bottom: 30px;
}
.item > div {
	margin-bottom: 0.2em;
}
.item:last-child {
	margin-bottom: 0;
}
.item:last-child > div:last-child {
	margin-bottom: 0;
}
.title {
	font-size: 1em;
	font-weight: 600;
}
strong {
	font-weight: 500;
	/* font-style: italic; */
}
a {
	color: var(--ion-text-color, #000);
	text-decoration: none;
}
</style>
<style scoped></style>
