<template>
	<!-- <base-layout page-title="List" title-button-type="menu-toggle"> -->
	<div class="main-container">
		<p>
			Verplaats de groene cursor of probeer automatisch uw positie te bepalen.<br />
			Schakel GPS in voor een beter resultaat.
		</p>
		<!-- <p>
			<ion-label>Positie: </ion-label><ion-text>{{ currentPositionReadable }}</ion-text>
		</p> -->
		<!-- <router-link to="/list"><ion-button>Toevoegen</ion-button></router-link> -->
		<!-- <ion-button @click="$emit('close')">Close Button!!</ion-button> -->
		<div class="locate-container">
			<ion-button class="locate-bttn" @click="$store.dispatch('getCurrentPosition')" :disabled="locatingButtonDisabled">
				<ion-icon slot="start" name="locate-outline"></ion-icon>
				{{ locatingStatusText }}
			</ion-button>
			<!-- <span class="locating-status-text"></span> -->
			<!-- <router-link :to="locatingButtonDisabled ? '' : '/list/add'"> -->
			<router-link to="/list/add" v-if="!locatingButtonDisabled">
				<ion-button class="add-bttn" :disabled="locatingButtonDisabled">
					<ion-icon slot="start" name="location-outline"></ion-icon>
					Hier Toevoegen
				</ion-button>
			</router-link>
		</div>
	</div>
	<!-- </base-layout> -->
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';

import { addIcons } from 'ionicons';
import { locateOutline, locationOutline } from 'ionicons/icons';

export default {
	components: { IonButton, IonIcon },
	computed: {
		locatingStatus() {
			return this.$store.getters.locatingStatus;
		},
		locatingButtonDisabled() {
			return this.$store.getters.locatingStatus === 'LOADING' ? true : false;
		},
		locatingStatusText() {
			return this.$store.getters.locatingStatusText;
		},
		currentPositionCoords() {
			return this.$store.getters.currentPosition;
		},
		currentPositionReadable() {
			return this.currentPositionCoords ? `Breedtegraad: ${this.currentPositionCoords.lat} / Lengtegraad: ${this.currentPositionCoords.lng}` : 'Onbekend...';
		},
	},
	created() {
		addIcons({
			'locate-outline': locateOutline,
			'location-outline': locationOutline,
		});
	},
};
</script>

<style scoped>
.main-container {
	position: fixed;
	bottom: 0;
	left: 37px;
	right: 37px;
	padding: 5px 20px 0px 20px;
	border-radius: 8px 8px 0 0;
	/* background: rgb(255, 255, 255); */
	/* background: rgba(238, 241, 247, 0.85); */
	/* box-shadow: 0 -2px 7px 1px rgba(0, 0, 0, 0.3); */
	max-height: 100%;
	overflow: auto;
	background-color: rgba(14, 32, 64, 0.95);
	color: #fff;
}
.locate-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	/* justify-content: center; */
	/* justify-items: center; */
	align-content: center;
	align-items: center;

	margin-bottom: 15px;
}
/* .locate-bttn {
	--padding-bottom: 10px;
	--padding-end: 10px;
	--padding-start: 10px;
	--padding-top: 10px;
} */
.locating-status-text {
	padding-left: 10px;
	padding-right: 10px;
}
.add-bttn {
	justify-self: end;
}
</style>
