<template>
	<center-page>
		<p>U bent niet bevoegd voor deze functionaliteit.</p>
		<p v-if="!loggedInUser"><router-link to="/account">Meld u aan</router-link> of kies een andere pagina in het menu.</p>
		<p v-else>Neem contact op met een administrator of kies een andere pagina in het menu.</p>
	</center-page>
</template>

<script>
import CenterPage from './CenterPage';

export default {
	components: {
		CenterPage,
	},
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		},
	},
};
</script>

<style scoped>
/* 992px */
</style>
